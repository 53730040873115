<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <div>
                <v-form ref="passwordForm" lazy-validation @submit.prevent="savePassword">
                    <v-row justify="center" align="center">
                        <v-col cols="11">
                            <h2 class="text-center pb-6">Reset Password</h2>
                            <v-text-field flat solo type="password" background-color="#f3f3f3" v-model="password" label="Password" :rules="passwordRules"></v-text-field>
                            <v-text-field flat solo type="password" background-color="#f3f3f3" v-model="confirmPassword" label="Confirm Password" :rules="confirmPasswordRules"></v-text-field>
                          <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
export default {
    name: 'ResetPassword',

    data() {
        return {
            password: '',
            confirmPassword: '',
            submitting: false,

            valid: true,
            snackbar: false,
            snackbar_text: '',

            passwordRules: [
                v => !!v ||  'Password is required',
                v => (v || '').length >= 6 || 'Password must have minimum 6 characters',
            ],
            confirmPasswordRules: [
                v => !!v ||  'Confirm Password is required',
                value => value === this.password || 'Password and Confirm Password do not match',
            ],
        }
    },

     methods: {
         savePassword() {
             if (this.$refs.passwordForm.validate()) {
                 this.saving = true;
                 let password = this.password

                 this.$store.dispatch('saveAccount', {
                     password,
                 }).then(() => {
                     this.saving = false;
                     this.snackbar_text = "Password updated";
                     this.snackbar = true;
                     //this.$router.replace('/home');
                     this.$store.dispatch('logout')
                        .then(() => {
                            this.$router.push('/login').catch(err => {});
                        })
                        .catch(err => {

                        });
                 }).catch(err => {
                     this.snackbar_text = "Error while updating";
                     this.snackbar = true;
                     this.saving = false;
                 });
             }
         },
    }
}
</script>
